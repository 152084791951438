(function () {
  if (typeof window.IOL === 'undefined') {
    window.IOL = { geo: {} }
  }

  window.IOL.geo = {
    OPENTIPO_CAP: 3,
    OPENTIPO_EXACT: 2,
    OPENTIPO_EXACT_WORDS: 1,
    OPENTIPO_INIT_WORD: 0,
    OPENTIPO_CASCADE: -1,
    TOPOTIPO_INIT_WORD: 0,
    LNG_ITALIAN: 'ita',
    LNG_ENGLISH: 'eng',
    LNG_FRENCH: 'fre',
    LNG_GERMAN: 'ger',
    AREA_ITALY: 'it',
    AREA_FRANCE: 'fr',
    SITO_API: 'ac_api',
    TP_STATO: 0,
    TP_REGIONE: 1,
    TP_PROVINCIA: 2,
    TP_ZONA: 3,
    TP_COMUNE: 4,
    TP_FRAZIONE: 5,
    RET_OK: 0,
    RET_TOP_NOK: 4, // Toponimo_non_trovato
    RET_CIV_NOK: 5, // Civico non trovato
    MISSP_OK: -1, // Nessuna correzione
    MISSP_C_IMP: 0, // Correzione improbabile
    MISSP_C_PRO: 1 // Correzione probabile
  }
})()
